<template>
  <div class="container">
    <div class="page-title">
      <h3>软件详情
        <el-divider direction="vertical"></el-divider>
        <span @click="$router.push('/soft')">返回</span>
      </h3>
    </div>
    <div class="wrap">
      <el-row class="row">
        <el-col :span="8"><span>软件名称：</span>{{appInfo.name}}</el-col>
        <el-col :span="8"><span>类型：</span>{{appInfo.type}}</el-col>
        <el-col :span="8"><span>版本：</span>{{appInfo.version}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="8"><span>管理者：</span>{{appInfo.manager}}</el-col>
        <el-col :span="16"><span>备注：</span>{{appInfo.note}}</el-col>
      </el-row>

      <div class="tab-wrap">
<!--        <el-button icon="el-icon-plus" type="text"  @click.prevent="addDevice" class="add-btn">为该软件添加关联主机</el-button>-->
        <el-tabs v-model="activeName" type="card" class="tab" @tab-click="switchTab">
          <el-tab-pane label="关系图谱" name="map">
            <div ref="graphChart" style="width:100%;height: 600px"></div>
          </el-tab-pane>
          <el-tab-pane label="关联主机" name="dev">
            <el-table :data="devlist" style="width: 100%">
              <el-table-column label="主机名称" prop="dev.name">
              </el-table-column>
              <el-table-column label="位置">
                <template slot-scope="scope">
                  {{scope.row.room}} {{scope.row.cab}}
                </template>
              </el-table-column>
              <el-table-column label="IP" prop="dev.ip">
              </el-table-column>
              <el-table-column label="软件占用端口" prop="safe_port">
              </el-table-column>
              <el-table-column label="软件版本" prop="version">
              </el-table-column>
              <el-table-column label="软件安全信息">
                <template slot-scope="scope">
                  <p v-if="scope.row.ref_waf">关联WAF：{{scope.row.ref_waf_name}}</p>
                  <p v-if="scope.row.ref_dbcheck">关联WAF：{{scope.row.ref_dbcheck_name}}</p>
                  <p v-if="scope.row.safe_data">数据加密：{{scope.row.safe_data}}</p>
                  <p v-if="scope.row.safe_auth">多因素认证：{{scope.row.safe_auth}}</p>
                  <p v-if="scope.row.safe_log">日志审计：{{scope.row.safe_log}}</p>
                  <p v-if="scope.row.safe_backup">数据备份：{{scope.row.safe_backup}}</p>
                </template>
              </el-table-column>
              <el-table-column label="主机管理者" prop="manager">
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span class="row-opt" @click="viewDevice(scope.row)"><el-link type="primary" icon="el-icon-document">查看设备</el-link></span>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagenation">
              <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage"
                      :page-sizes="[10, 20, 50]"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="total">
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addDevicePop" :close-on-click-modal="false" @close="closePop" width="65%">
      <hw-softdevice-form :soft-id="id" :soft-type="appInfo.type_id" :version="appInfo.version" :name="appInfo.name" :room-id="roomId" :cab-d="cabId" :mode="mode" :id="refId" @closePop="closePop"></hw-softdevice-form>
    </el-dialog>


  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import SoftdeviceForm from "@/components/pages/SoftdeviceForm";
  const option = {
    legend: [{
      //selectedMode: 'single'
    }],
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',

    series: [
      {
        //name: '关系图',
        type: 'graph',
        legendHoverLink: false, //启用图例hover联动高亮
        layout: 'force', //采用力引导布局
        selectedMode: 'multiple',
        select: {
          label: {
            show: true
          },
          // edgeLabel: {
          //     show: true
          // }
        },
        data: [],
        links: [],
        categories: [
          {
            "name": "主机"
          },
          {
            "name": "应用"
          },
          {
            "name": "其他"
          }
        ],
        roam: true, //开启缩放和平移
        label: {
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        //连接线标签
        edgeLabel: {
          show: false,
          //formatter: '{b}:{c}'
        },
        force: {
          repulsion: 800
        },
        lineStyle: {
          color: 'source',
          width: 2,
          curveness: 0.3
        },
        scaleLimit: {
          min: 0.5,
          max: 3
        },
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 10
          },
          label: {
            show: true
          }
        },
        labelLayout: {
          hideOverLap: true,
          moveOverlap: 'shiftY'
        },
        tooltip: {
          formatter: '{b}'  //c:数值
        }
      }
    ]
  };

  export default {
    name: "ApplicationDetail",
    components: {
      hwSoftdeviceForm: SoftdeviceForm,
    },
    data() {
      return {
        id: parseInt(this.$route.query.id),
        activeName: 'map',
        appInfo: {
        },
        devlist: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        loading: false,

        addDevicePop: false,
        refId: null,
        mode: 'add',
        popTitle: '添加关联主机',
        roomId: null,
        cabId: null,
      }
    },
    created() {
      this.loadDetail();
    },
    beforeRouteLeave (to, from , next) {
      this.clearChart();
      next();
    },
    methods: {
      createChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        myChart.showLoading();
        //myChart.setOption(option, true);
        let app = [
          {
            id: "app_" + this.appInfo.id,
            name: this.appInfo.name,
            symbolSize: 50,
            category: 1
          },
          {
            id: "dev0",
            name: '主机',
            symbolSize: 40,
            category: 0
          }
        ];
        app[0].label = {
          show: true,
          fontWeight: 'bold',
          fontSize: '18px'
        }
        let links = []

        this.$get(this.$api.GetAppChart, {id: this.$route.query.id}).then((res) => {
          myChart.hideLoading();
          let arr = res.nodes;
          let nodes = [...app, ...arr];
          option.series[0].data = nodes;
          option.series[0].links = [...links, ...res.links];
          myChart.setOption(option);
        });
      },
      clearChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        //myChart.clear();
        option.series[0].data = [];
        myChart.setOption(option);
        myChart.dispose(); //销毁实例
      },
      loadDetail() {
        this.$get(this.$api.GetSoftInfo, {id: this.id}).then((res) => {
          if (res.code === 0) {
            this.appInfo = res.data;
            this.createChart();
          } else {
            this.$message.error(res.message)
          }
        });
      },
      getDevlist() {
        let params = {
          soft_id: this.id,
          page: this.currentPage,
          limit: this.pageSize
        }
        this.$get(this.$api.GetDevOfSoft, params).then((res) => {
          if (res.code === 0) {
            this.devlist = res.data.list;
            this.total = res.data.total_count;
          } else {
            this.$message.error(res.message)
          }
        });
      },
      handleCurrentChange (val) {
        this.currentPage = val
        this.getDevlist()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getDevlist()
      },
      viewDevice(row) {
        this.$router.push({path: '/device/detail', query: {id: row.dev_id}});
      },

      closePop() {
        this.addDevicePop = false;
        this.popTitle = '编辑关联主机';
        this.refId = null;
        this.mode = 'add';
        this.activeName = 'dev';
        this.getDevlist();
      },

      switchTab(tab) {
        this.activeName = tab.name;
        if (tab.name == 'dev') {
          this.$nextTick(()=>{
            this.getDevlist();
          });
        } else if (tab.name == 'map') {
          this.$nextTick(()=>{
            this.createChart();
          });
        }
      },

      addDevice() {
        this.addDevicePop = true;
      },

      editMap(row) {
        this.popTitle = '编辑关联主机';
        this.addDevicePop = true;
        this.mode = 'edit';
        this.refId = row.id;
        this.roomId = row.dev.room_id;
        this.cabId = row.dev.cab_id;
      },

      //删除应用关联的主机
      deleteDev(id) {
        this.$confirm('此操作将删除该应用关联的设备吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteDevSoft, {id: id})
              .then((res) => {
                if (res.code === 0) {
                  this.$message({
                    message: '操作成功！',
                    type: 'success'
                  });
                  this.getDevlist();
                } else {
                  this.$message.error(res.message);
                }
              });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

    }
  }
</script>

<style scoped lang="less">
  .tab-wrap{
    position: relative;
    .add-btn{
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999;
    }
  }

  .row{
    margin-top: 20px;
    padding: 4px 0;
    span{
      color: #888;
    }
    i{
      font-style: normal;
    }
  }
  .tab{
    margin-top: 20px;
    .row{
      margin-top: 6px;
    }
    p{
      //padding: 10px 0;
      span{
        color: #888;
      }
    }
  }
  .edit-ipt{
    width: 120px;
  }
  .rows{
    line-height: 42px;
    font-size: 14px;
    .label-txt{
      color: #999;
      font-size: 15px;
      text-align: right;
    }
  }
  .pagenation{
    margin: 20px auto;
    text-align: center;
  }
</style>
