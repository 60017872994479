<template>
    <div class="container">
        <el-form :model="form" label-width="140px" @submit.prevent="addSubmitApp">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="主机位置" prop="soft_id">
                        <el-select v-model="roomId" placeholder="请选择机房" @change="changeRoom" style="width: 120px">
                            <template v-for="item in roomlist">
                                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </template>
                        </el-select> &nbsp;
                        <el-select v-model="cab_id" placeholder="请选择机柜" @change="changeCab" style="width: 120px">
                            <el-option key="0" label="请选择" value="0"></el-option>
                            <template v-for="item in cablist">
                                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="主机名称" prop="soft_id">
                        <el-select v-model="dev_id" filterable placeholder="请选择">
                            <el-option key="0" label="请选择" value="0"></el-option>
                            <el-option
                                    v-for="item in devlist"
                                    :key="item.id"
                                    :label="item.name + '(' + item.ip + ')'"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="当前版本" prop="version" >
                        <el-input v-model="form.version" placeholder="选填" style="width: 100px"></el-input>
                        &nbsp; <el-checkbox v-model="useVersion" @change="selVersion">{{name}}最新版本{{version}}</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开放端口" prop="param" >
                        <el-input v-model="form.safe_port" style="width: 217px" placeholder="选填，输入端口，如3306,443"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="关联WAF" prop="ref_waf" >
                        <el-select v-model="form.ref_waf" filterable>
                            <el-option :value="0" label="----无----" style="color: #999"></el-option>
                            <el-option
                                    v-for="item in wafList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="关联数据库审计" prop="ref_data_check" >
                        <el-select v-model="form.ref_dbcheck" filterable>
                            <el-option :value="0" label="----无----" style="color: #999"></el-option>
                            <el-option
                                    v-for="item in datacheckList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="数据加密" prop="safe_data" >
                <el-checkbox-group v-model="form.safe_data">
                    <el-checkbox :label="item" v-for="item in encryptList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="多因素认证" prop="safe_auth" >
                <el-checkbox-group v-model="form.safe_auth">
                    <el-checkbox :label="item" v-for="item in authList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="数据备份与恢复" prop="safe_backup" >
                <el-checkbox-group v-model="form.safe_backup">
                    <el-checkbox :label="item" v-for="item in backupList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日志审计" prop="safe_log" >
                <el-checkbox-group v-model="form.safe_log">
                    <el-checkbox :label="item" v-for="item in logList" :key="item" style="width: 140px"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="备注" prop="note" >
                <el-input v-model="form.note" placeholder="选填，如：主数据库"></el-input>
            </el-form-item>
            <div style="margin-top:30px; text-align: center">
                <el-button type="primary" native-type="submit" @click.prevent="addSubmitApp">提交</el-button>
                <el-button @click="closePop">取消</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "SoftdeviceForm",
        props: {
            softId: {
                type: Number
            },
            id: {
                type: Number
            },
            softType: {
                type: Number
            },
            name: {
                type: String
            },
            version: {
                type: String
            },
            roomId: {
                type: Number
            },
            cabId: {
                type: Number
            },
            mode: {
                type: String
            },
        },
        data() {
            return {
                typelist: [
                    {id: 1, name: "物理机"},
                    {id: 2, name: "虚拟机"},
                    {id: 3, name: "其他"}
                ],
                devlist: [],
                roomlist: [],
                cablist: [],
                wafList: [],
                datacheckList: [],
                encryptList: [],
                authList: [],
                backupList: [],
                logList: [],
                adminList: [],
                useVersion: false,
                latestVersion: '1.0',
                safe_data: [],
                safe_auth: [],
                form: {
                    soft_id: '',
                    safe_data: [],
                    safe_auth: [],
                    safe_backup: [],
                    safe_log: [],
                    safe_admin: [],
                },
                cab_id: null,
                dev_id: null,
            }
        },
        watch: {
            id(val, oldVal) {
                if (val != oldVal) {
                    this.getMapInfo();
                }
            }
        },
        mounted() {
            this.cab_id = this.form.cab_id;
            this.getMapInfo();
        },
        created() {
            this.getRoomList();
            //this.getTypeList();
            this.getSoftList(12); //WAF
            this.getSoftList(13); //数据库审计
            this.getOption('app'); //应用安全信息
            if (this.form.room_id) {
                this.getCabList();
            }
        },
        methods: {
            getMapInfo() {
                if (this.mode == 'edit') {
                    this.$get(this.$api.GetDevSoftInfo, {id: this.id}).then((res) => {
                        if (res.code === 0) {
                            this.form = res.data;
                            this.dev_id = res.data.dev_id;
                            this.getDevlist();
                            this.form.safe_data = this.form.safe_data ? this.form.safe_data.split(',') : [];
                            this.form.safe_auth = this.form.safe_auth ? this.form.safe_auth.split(',') : [];
                            this.form.safe_backup = this.form.safe_backup ? this.form.safe_backup.split(',') : [];
                            this.form.safe_log = this.form.safe_log ? this.form.safe_log.split(',') : [];
                            this.form.safe_admin = this.form.safe_admin ? this.form.safe_admin.split(',') : [];
                        } else {
                            this.$message.error(res.message)
                        }
                    });
                } else {
                    this.form = {
                        soft_id: '',
                        safe_data: [],
                        safe_auth: [],
                        safe_backup: [],
                        safe_log: [],
                        safe_admin: [],
                    };
                }
            },
            //机房列表
            getRoomList() {
                this.$get(this.$api.GetRoomList).then((res) => {
                    if (res.code === 0) {
                        this.roomlist = res.data.list;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            //机柜列表
            getCabList() {
                this.$get(this.$api.GetCabList, {room_id: this.roomId}).then((res) => {
                    if (res.code === 0) {
                        this.cablist = res.data.list;
                        //this.form.cab_id = res.data.list[0].id;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            changeRoom(val) {
                this.roomId = val;
                this.loadCablist();
                this.dev_id = null;
                this.getDevlist();
            },
            changeCab() {
                this.dev_id = null;
                this.getDevlist();
            },
            loadCablist() {
                this.cab_id = null;
                this.getCabList();
            },
            getDevlist() {
                let params = {
                    room_id: this.form.room_id,
                    cab_id: this.cab_id
                }
                this.$get(this.$api.GetDeviceSimpleList, params).then((res) => {
                    if (res.code === 0) {
                        this.devlist = res.data;
                    }
                });
            },

            getSoftList(selType) {
                this.$get(this.$api.GetSoftSimpleList, {type: selType}).then((res) => {
                    if (res.code === 0) {
                        if (selType == 12) { //waf
                            this.wafList = res.data;
                        } else if (selType == 13) { //数据库审计
                            this.datacheckList = res.data;
                        } else {
                            this.softlist = res.data;
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            getOption(mod) {
                this.$get(this.$api.GetSafeOptions, {field: '', mod: mod}).then((res) => {
                    if (res.code === 0) {
                        this.encryptList = res.data.safe_data;
                        this.authList = res.data.safe_auth;
                        this.backupList = res.data.safe_backup;
                        this.logList = res.data.safe_log;
                        this.adminList = res.data.safe_admin;
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            selVersion(checked) {
                this.form.version = checked ? this.version : '';
            },
            //提交保存
            addSubmitApp() {
                this.form.cab_id = this.cab_id;
                this.form.dev_id = this.dev_id;
                if (this.form.dev_id === '') {
                    this.$message.warning('请选择关联的主机名称');
                    return false;
                }

                if (!this.softId) {
                    this.$message.warning('获取当前应用信息失败');
                    return false;
                }
                this.form.soft_id = this.softId;

                this.form.soft_type = this.softType;

                let safeData = this.form.safe_data;
                if (safeData.length > 0) {
                    this.form.safe_data = this.form.safe_data.join(',');
                }
                let safeAuth = this.form.safe_auth;
                if (safeAuth.length > 0) {
                    this.form.safe_auth = this.form.safe_auth.join(',');
                }
                let safeBackup = this.form.safe_backup;
                if (safeBackup.length > 0) {
                    this.form.safe_backup = safeBackup.join(',');
                }
                let safeLog = this.form.safe_log;
                if (safeLog.length > 0) {
                    this.form.safe_log = safeLog.join(',');
                }
                let safeAdmin = this.form.safe_admin;
                if (safeAdmin.length > 0) {
                    this.form.safe_admin = safeAdmin.join(',');
                }

                let postUrl = this.$api.AddDevSoft;
                if (this.mode == 'edit') {
                    postUrl = this.$api.EditDevSoft;
                }
                this.$post(postUrl, this.form).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.closePop();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            closePop() {
                this.$emit('closePop');
            },
        }
    }
</script>

<style scoped>

</style>